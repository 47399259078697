.pushbot-quote > p {
  white-space: pre-wrap;
}

.pushbot-quote-mode {
  font-weight: bold;
  max-width: 10em;
}

.pushbot-quote-query {
  width: 30em;
}

.pushbot-quote-mode option {
  font-weight: bold;
}

.pushbot-random-quote .well {
  margin: 15px 0;
}

.pushbot-random-quote button i {
  margin-right: 10px;
}
