.pushbot-loading {
  margin-top: 20%;
  color: #ccc;
  font-size: 300%;
  text-align: center;
}

.pushbot-loading i {
  margin-right: 20px;
}
